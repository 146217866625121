import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./LoginPage.css";
import logo from "../../assets/images/logo/logo.png";
import { useDispatch, useSelector } from "react-redux";
import {
  logOut,
  selectCurrentToken,
  setCredentials,
} from "../../features/auth/authSlice";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import toast, { Toaster } from "react-hot-toast";
import { authTypes } from "../../enums/authTypes";
import {
  getAuthURL,
  handleAuthUpdate,
  setupLoginSync,
} from "../../components/common/utils/utils";
import LoadingSpinner from "../../components/common/LoadingSpinner/LoadingSpinner";

const getTokenFromUrlOrLocalStorage = () => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get("token") || localStorage.getItem("token");
};

const LoginPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentToken = useSelector(selectCurrentToken);
  const [loading, setLoading] = useState(true);
  const authURL = useMemo(() => getAuthURL(), []);
  const redirectPath = useMemo(() => {
    return localStorage.getItem("redirectPath") || "/my-invoices";
  }, []);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const previousVisitedPath =
      location.state?.from?.pathname + location.state?.from?.search ||
      "/my-invoices";
    if (!["/", "/not-found", "/no-permission"].includes(previousVisitedPath)) {
      localStorage.setItem("redirectPath", previousVisitedPath);
    }
  }, []);

  useEffect(() => {
    const cleanup = setupLoginSync(navigate, redirectPath);
    return cleanup;
  }, [navigate, redirectPath]);

  useEffect(() => {
    const token = getTokenFromUrlOrLocalStorage();

    if (currentToken && window.location.pathname !== redirectPath) {
      navigate(redirectPath);
    } else if (token) {
      handleTokenLogin(token);
    } else if (!JSON.parse(localStorage.getItem("isLoggedOut"))) {
      redirectToLogin();
    } else {
      setLoading(false);
    }
  }, [currentToken, navigate, redirectPath]);

  const handleTokenLogin = useCallback(
    async (token) => {
      if (!token) return;
      setLoading(true);

      try {
        const user = await getUserDetails(token);
        if (user) {
          dispatch(setCredentials({ token, user }));
          localStorage.setItem("token", token);
          localStorage.setItem("user", JSON.stringify(user));
          window.history.replaceState(null, null, window.location.pathname);
          navigate(redirectPath);
          handleAuthUpdate("loggedIn");
        } else {
          throw new Error("Invalid user details");
        }
      } catch (error) {
        handleLoginError("Error validating token:", error);
      } finally {
        setLoading(false);
      }
    },
    [dispatch]
  );

  const getUserDetails = async (token) => {
    const tokenData = jwtDecode(token);
    const guid = tokenData?.sub;
    const response = await axios.get(`${authURL}/v1/companies/users/${guid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  const redirectToLogin = useCallback(() => {
    const redirectURL = `${window.location.origin}`;
    window.location.href = `${authURL}/#/portal?continue=${redirectURL}?authtype=${authTypes.FALSE}`;
  }, [authURL]);

  const refreshTokenHandler = useCallback(async () => {
    try {
      const refreshTokenResponse = await axios.post(
        `${authURL}/v1/auth/tokens:refresh`,
        null,
        {
          withCredentials: true,
        }
      );
      const newToken = refreshTokenResponse.data.token;
      const user = await getUserDetails(newToken);
      dispatch(setCredentials({ token: newToken, user }));
      localStorage.setItem("token", newToken);
      localStorage.setItem("user", JSON.stringify(user));
      window.history.replaceState(null, null, window.location.pathname);
      navigate(redirectPath);
    } catch (refreshError) {
      console.error("Error refreshing token:", refreshError);
      redirectToLogin();
    }
  }, [authURL, dispatch, navigate, redirectPath]);

  const handleLoginError = useCallback(
    (message, error) => {
      if (error?.response?.status === 401) {
        refreshTokenHandler();
      } else {
        dispatch(logOut());
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        toast.error(message);
      }
    },
    [dispatch, refreshTokenHandler]
  );

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="container-fluid login">
      <Toaster
        position="top-right"
        toastOptions={{
          success: {
            iconTheme: {
              primary: "#180080",
              secondary: "#f3f3f3",
            },
          },
        }}
      />
      <div
        className="row justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <div className="col-12 col-md-auto col-lg-4 col-xl-4">
          <div className="card shadow-sm mx-auto">
            <div className="card-body mt-5 mx-4 mb-4">
              <div className="text-center mb-1">
                <img
                  src={logo}
                  alt="Intellimorph"
                  className="img-fluid"
                  width={200}
                />

                {JSON.parse(localStorage.getItem("isLoggedOut")) && (
                  <>
                    <h3 className="">You signed out of your account</h3>
                    <div className="my-4">
                      <button
                        className="btn btn-gradiant w-100"
                        onClick={redirectToLogin}
                      >
                        Login
                      </button>
                    </div>
                  </>
                )}
              </div>
              <div className="text-center">
                <small>
                  All rights reserved. {currentYear}{" "}
                  {process.env.REACT_APP_VERSION} {process.env.REACT_APP_ENV}{" "}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
